import React, {createContext, useState} from 'react'
import {Route, Routes} from 'react-router-dom'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import ScreenLoginEmail from '@feature/common/components/LoginModal/screen/ScreenLoginEmail'
import ScreenLoginWithPassword from '@feature/common/components/LoginModal/screen/ScreenLoginWithPassword'
import ScreenLoginWithCode from '@feature/common/components/LoginModal/screen/ScreenLoginWithCode'

interface IProps extends ILoginModalProps {}

export const LoginContext = createContext({
    email: '',
    setEmail: (text?: string) => {},
    code: '',
    setCode: (text?: string) => {},
    password: '',
    setPassword: (text?: string) => {},
    confirmPassword: '',
    setConfirmPassword: (text?: string) => {},
})

const LoginRoutes: React.FC<IProps> = ({closeModal}) => {
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    return (
        <LoginContext.Provider
            value={{
                email,
                setEmail,
                code,
                setCode,
                password,
                setPassword,
                confirmPassword,
                setConfirmPassword,
            }}>
            <Routes>
                <Route path={'/login_email'} element={<ScreenLoginEmail closeModal={closeModal} />} />
                <Route path={'/login_code'} element={<ScreenLoginWithCode closeModal={closeModal} />} />
                <Route path={'/login_password'} element={<ScreenLoginWithPassword closeModal={closeModal} />} />
            </Routes>
        </LoginContext.Provider>
    )
}

export default LoginRoutes
