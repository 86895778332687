import React, {createContext, useState} from 'react'
import {Route, Routes} from 'react-router-dom'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import ScreenRegisterName from '@feature/common/components/LoginModal/screen/ScreenRegisterName'
import ScreenRegisterEmailConfirm from '@feature/common/components/LoginModal/screen/ScreenRegisterEmailConfirm'
import ScreenRegisterSetPassword from '@feature/common/components/LoginModal/screen/ScreenRegisterSetPassword'
import ScreenRegisterReferral from '@feature/common/components/LoginModal/screen/ScreenRegisterReferral'
import ScreenRegisterWelcome from '@feature/common/components/LoginModal/screen/ScreenRegisterWelcome'

interface IProps extends ILoginModalProps {}

export const RegisterContext = createContext({
    email: '',
    setEmail: (text?: string) => {},
    name: '',
    setName: (text?: string) => {},
    code: '',
    setCode: (text?: string) => {},
    password: '',
    setPassword: (text?: string) => {},
    confirmPassword: '',
    setConfirmPassword: (text?: string) => {},
    referral: '',
    setReferral: (text?: string) => {},
})

const RegisterRoutes: React.FC<IProps> = ({closeModal}) => {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [referral, setReferral] = useState('')

    return (
        <RegisterContext.Provider
            value={{
                email,
                setEmail,
                name,
                setName,
                code,
                setCode,
                password,
                setPassword,
                confirmPassword,
                setConfirmPassword,
                referral,
                setReferral,
            }}>
            <Routes>
                <Route path={'/register'} element={<ScreenRegisterName closeModal={closeModal} />} />
                <Route path={'/register_code'} element={<ScreenRegisterEmailConfirm closeModal={closeModal} />} />
                <Route path={'/register_password'} element={<ScreenRegisterSetPassword closeModal={closeModal} />} />
                <Route path={'/register_referral'} element={<ScreenRegisterReferral closeModal={closeModal} />} />
                <Route path={'/register_done'} element={<ScreenRegisterWelcome closeModal={closeModal} />} />
            </Routes>
        </RegisterContext.Provider>
    )
}

export default RegisterRoutes
