import React, {createContext, useState} from 'react'
import {Route, Routes} from 'react-router-dom'
import ScreenForgotPassword from '@feature/common/components/LoginModal/screen/ScreenForgotPassword'
import ScreenForgotPasswordCode from '@feature/common/components/LoginModal/screen/ScreenForgotPasswordCode'
import ScreenForgotPasswordSetPassword from '@feature/common/components/LoginModal/screen/ScreenForgotPasswordSetPassword'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'

interface IProps extends ILoginModalProps {}

export const FindPasswordContext = createContext({
    email: '',
    setEmail: (text?: string) => {},
    code: '',
    setCode: (text?: string) => {},
    password: '',
    setPassword: (text?: string) => {},
    confirmPassword: '',
    setConfirmPassword: (text?: string) => {},
})

const FindPasswordRoutes: React.FC<IProps> = ({closeModal}) => {
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    return (
        <FindPasswordContext.Provider
            value={{
                email,
                setEmail,
                code,
                setCode,
                password,
                setPassword,
                confirmPassword,
                setConfirmPassword,
            }}>
            <Routes>
                <Route path={'/find_password'} element={<ScreenForgotPassword closeModal={closeModal} />} />
                <Route path={'/find_password_code'} element={<ScreenForgotPasswordCode closeModal={closeModal} />} />
                <Route
                    path={'/find_password_set_password'}
                    element={<ScreenForgotPasswordSetPassword closeModal={closeModal} />}
                />
            </Routes>
        </FindPasswordContext.Provider>
    )
}

export default FindPasswordRoutes
